@font-face {
  font-family: 'foodieorder-iconfont';
  src: url('./foodieorder-iconfont.eot'); /* IE9 Compat Modes */
  src: url('./foodieorder-iconfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./foodieorder-iconfont.woff2') format('woff2'), /* Super Modern Browsers */
       url('./foodieorder-iconfont.woff') format('woff'), /* Pretty Modern Browsers */
       url('./foodieorder-iconfont.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('./foodieorder-iconfont.svg#svgFontName') format('svg'); /* Legacy iOS */
}


[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "foodieorder-iconfont";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  text-align: center;

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}


.icon-duplicate:before {
  content: "\e800"
}
.icon-user:before {
  content: "\e801"
}
.icon-delete:before {
  content: "\e802"
}
.icon-edit:before {
  content: "\e803"
}
.icon-link:before {
  content: "\e804"
}
.icon-arrow-down:before {
  content: "\e805"
}
.icon-arrow-up:before {
  content: "\e806"
}
.icon-collapse:before {
  content: "\e807"
}
.icon-expand:before {
  content: "\e808"
}
.icon-save:before {
  content: "\e809"
}
.icon-phone:before {
  content: "\e80a"
}
.icon-sms:before {
  content: "\e80b"
}
.icon-fax:before {
  content: "\e80c"
}
.icon-email:before {
  content: "\e80d"
}
.icon-online:before {
  content: "\e80e"
}
.icon-close:before {
  content: "\e80f"
}
.icon-clock:before {
  content: "\e810"
}
.icon-pizza-full:before {
  content: "\e811"
}
.icon-pizza-left:before {
  content: "\e812"
}
.icon-pizza-right:before {
  content: "\e813"
}
.icon-archive:before {
  content: "\e814"
}
.icon-address:before {
  content: "\e815"
}
.icon-past-orders:before {
  content: "\e816"
}
.icon-settings:before {
  content: "\e817"
}
.icon-checkbox-on:before {
  content: "\e818"
}
.icon-reconnect:before {
  content: "\e819"
}
.icon-search:before {
  content: "\e81a"
}
.icon-arrow-right:before {
  content: "\e81b"
}
.icon-logout:before {
  content: "\e81c"
}
.icon-help:before {
  content: "\e81d"
}
.icon-menu-availability:before {
  content: "\e81e"
}
.icon-view-orders:before {
  content: "\e81f"
}
.icon-close-toast:before {
  content: "\e820"
}
.icon-checkbox-off:before {
  content: "\e821"
}
.icon-paid:before {
  content: "\e822"
}
.icon-favorite-selected-small:before {
  content: "\e823"
}
.icon-locations-small:before {
  content: "\e824"
}
.icon-logout--small:before {
  content: "\e825"
}
.icon-minus-small:before {
  content: "\e826"
}
.icon-mobile-number-small:before {
  content: "\e827"
}
.icon-my-account-small:before {
  content: "\e828"
}
.icon-note-small:before {
  content: "\e829"
}
.icon-orders-small:before {
  content: "\e82a"
}
.icon-payments-small:before {
  content: "\e82b"
}
.icon-pizza-full-small:before {
  content: "\e82c"
}
.icon-pizza-left-small:before {
  content: "\e82d"
}
.icon-pizza-right-small:before {
  content: "\e82e"
}
.icon-plus-small:before {
  content: "\e82f"
}
.icon-promotions-small:before {
  content: "\e830"
}
.icon-radio-blank-small:before {
  content: "\e831"
}
.icon-radio-selected-small:before {
  content: "\e832"
}
.icon-schedule-small:before {
  content: "\e833"
}
.icon-sign-in-and-security-small:before {
  content: "\e834"
}
.icon-soonest-available-small:before {
  content: "\e835"
}
.icon-square-small:before {
  content: "\e836"
}
.icon-thank-you-small:before {
  content: "\e837"
}
.icon-address-small:before {
  content: "\e838"
}
.icon-address-selected-small:before {
  content: "\e839"
}
.icon-arrow-left-small:before {
  content: "\e83a"
}
.icon-arrow-left-thin-small:before {
  content: "\e83b"
}
.icon-arrow-right-small:before {
  content: "\e83c"
}
.icon-arrow-right-thin-small:before {
  content: "\e83d"
}
.icon-bag-small:before {
  content: "\e83e"
}
.icon-check-small:before {
  content: "\e83f"
}
.icon-checkbox-blank-small:before {
  content: "\e840"
}
.icon-checkbox-selected-small:before {
  content: "\e841"
}
.icon-credit-card-small:before {
  content: "\e842"
}
.icon-cross-small:before {
  content: "\e843"
}
.icon-cross-thin-small:before {
  content: "\e844"
}
.icon-dropdown-small:before {
  content: "\e845"
}
.icon-edit-small:before {
  content: "\e846"
}
.icon-email-small:before {
  content: "\e847"
}
.icon-favorite-small:before {
  content: "\e848"
}
.icon-favorite-address-small:before {
  content: "\e849"
}
.icon-categories-small:before {
  content: "\e84a"
}
.icon-arrow-short-right-small:before {
  content: "\e84b"
}
.icon-arrow-short-left-small:before {
  content: "\e84c"
}
.icon-menu-small:before {
  content: "\e84d"
}
.icon-shopping-bag:before {
  content: "\e84e"
}
.icon-menu-availability-small:before {
  content: "\e84f";
}
.icon-fax-small:before {
  content: "\e850";
}
.icon-past-orders-small:before {
  content: "\e851";
}
.icon-help-small:before {
  content: "\e852";
}
.icon-settings-small:before {
  content: "\e853";
}
.icon-arrow-left-nomargin:before {
  content: "\e854";
}
.icon-arrow-left-thin-normargin:before {
  content: "\e855";
}