@import 'assets/fonts/foodieorder-iconfont.css';

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-LightItalic.woff2") format("woff2"),
    url("./assets/fonts/Roboto-LightItalic.woff") format("woff"),
    url("./assets/fonts/Roboto-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Bold.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Bold.woff") format("woff"),
    url("./assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Light.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Light.woff") format("woff"),
    url("./assets/fonts/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Regular.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Regular.woff") format("woff"),
    url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Light.woff2") format("woff2"),
    url("./assets/fonts/Montserrat-Light.woff") format("woff"),
    url("./assets/fonts/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Regular.woff2") format("woff2"),
    url("./assets/fonts/Montserrat-Regular.woff") format("woff"),
    url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/Montserrat-SemiBold.woff") format("woff"),
    url("./assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

i {
  display: inline-block;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  background: #f3f4f5;
}

html,
body,
#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.rc-time-picker-panel-inner {
  width: 100%;
}

.rc-time-picker-panel-inner input {
  width: 100%;
  font-size: 17px;
  font-family: "Roboto", sans-serif;
  color: rgb(66, 70, 72);
  font-weight: lighter;
  outline: none;
  border: none;
}

.rc-time-picker-panel-input-wrap {
  padding: 10px !important;
}

.rc-time-picker-panel-clear-btn {
  top: 11px !important;
}

.rc-time-picker-input {
  height: 41px;
}

.rc-time-picker-panel-select {
  width: 50% !important;
}

.rc-time-picker-panel-select li {
  box-sizing: border-box;
  height: auto !important;
  padding: 5px !important;
  text-align: center !important;
  font-size: 18px;
  font-size: "Roboto", sans-serif;
  width: auto !important;
}

.rc-time-picker-panel-select li:hover,
.rc-time-picker-panel-select-option-selected {
  background: #f3f4f5 !important;
}

html {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  position: relative;
  -webkit-overflow-scrolling: touch;
}

#root {
  overflow: hidden;
}

/* BREAKS WORK OF APPLICATION LAYOUTS */
/* body {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
} */

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}

.no-scroll {
  overflow-y: hidden;
}

.slide-exit {
  position: relative;
  left: 0;
  max-height: 500px;
}
.slide-exit-active {
  left: -500px;
  max-height: 0;
  transition: left 500ms ease-in 0ms, max-height 500ms ease-in 250ms;
}


.collapse-enter {
  opacity: 0;
  transform: scaleY(0);
  max-height: 0;
}
.collapse-enter-active {
  opacity: 1;
  transform: scaleY(1);
  transition: opacity 250ms ease-in 0ms, transform 250ms ease-in 0ms, max-height 300ms linear 100ms;
  max-height: 500px;
}
.collapse-exit {
  opacity: 1;
  transform: scaleY(1);
  max-height: 500px;
}
.collapse-exit-active {
  opacity: 0;
  transform: scaleY(0);
  max-height: 0;
  transition: opacity 250ms ease-in 0ms, transform 250ms ease-in 0ms, max-height 300ms linear 100ms;
}

.uwy {
  bottom: 83px !important;
}

@media (min-width: 1155px) {
  .uwy {
    bottom: 30px !important;
  }
}

button {
  padding: 0;
  width: 100%;
  text-align: left;
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1; /* 1 */
  margin: 0; /* 2 */
  color: inherit;
  overflow: visible;
  text-transform: none;
  background: none;
  border: none;
  -webkit-appearance: button;
}

*:focus{
  outline:none!important ;
  outline-width:0!important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}